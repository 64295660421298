import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Button from "../components/Button"
import Moment from "react-moment"
import ReactMarkdown from "react-markdown"
import Embarc_Logo from '../images/Embarc_Logo.svg'
//import AddToCalendar from "react-add-to-calendar"
import "../utils/addToCalendar.css"

const Event = ({ data }) => {
  const event = data.airtable.data
  const cal_event = {
    title: event.Name,
    description: event.Description,
    location: "802 E Whiting Street Tampa, FL 33604",
    startTime: event.Start,
    endTime: event.End,
  }
  const past = Date.now() > Date.parse(event.End)
  const bgColor = "lightgrey"
  return (
    <Layout bgColor={bgColor} headerColor="rgba(255,255,255,0.3)" hideHeader unsetWidth>
      <div style={{
        zIndex: '10',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        margin: '2.5rem',
        padding: '1rem 3rem 4rem 3rem',
        backgroundColor: 'white',
        borderRadius: '25px'
      }} key={event.Id}>
        <div role="content" style={{ minWidth: 250, maxWidth: 600, overflow: 'hidden' }}>
          <Link
            to="/events"
            alt="Go back to all Events"
            style={{
              color: `white`,
              textDecoration: `none`,
              margin: '2rem 1rem 2rem 1rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img style={{ margin: `auto 0 auto 0`, maxWidth: 120 }} src={Embarc_Logo} width='100%' height="auto" alt="Embarc Collective Logo" />
          </Link>
          <h3 role="title" style={{ margin: '0 0 1rem 0' }}>{event.Name}</h3>
          <div style={{ margin: '1rem 0', fontWeight: 'bold' }}>
            {
              past ?
                <Moment format="MMM DD, YYYY">{event.Start}</Moment> :
                <div>
                  <Moment format="dddd MMMM DD">{event.Start}</Moment>, <Moment format="h:mm a">{event.Start}</Moment>–<Moment format="h:mm a">{event.End}</Moment>
                </div>
            }
          </div>
          <ReactMarkdown source={event.Description} />
          <div style={{ marginBottom: '2rem' }}>
            {!past && <iframe title="rsvp" className="airtable-embed-form-session" src={`https://airtable.com/embed/shrthsKwPhKDertrl?backgroundColor=teal&prefill_Event=${event.Id}`} frameBorder="0" width="100%" height="533" style={{ background: 'transparent', marginBottom: 0, border: '1px solid #ccc' }}></iframe>}
            {!past && <a href={`https://airtable.com/shrthsKwPhKDertrl?backgroundColor=teal&prefill_Event=${event.Id}`}>Form not working? Click here.</a>}
          </div>
        </div>
        <Link style={{ margin: '1rem 0' }} to="/events">Back to all events</Link>
      </div>
    </Layout>
  )
}

export default Event

export const query = graphql`
  query($Id: String!) {
    airtable(data: { Id: {eq: $Id}}) {
      data {
        Id
        Name
        Start
        End
        Description
      }
    }
  }
`
